@media screen and (min-width: 0px) {
  .item {
    width: 100%;
    border-radius: 5px;
    display: flex;
    /* align-items: center;
    justify-content: center; */
    font-family: "Syne Mono", monospace;
    height: 250px;
    color: rgba(241, 235, 235, 0.718);
    font-size: 20px;
    flex-direction: column;
    overflow: scroll;
  }
  .itemTitle {
    font-size: 40px;
    margin-bottom: 40px;
  }
  .education {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    
    height: 300px;
  }
  .educationLeft {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .educationRight {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .logo1 {
    width: 150px;
    height: 100px;
    background-image: url("../../images/uek.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 20px;
  }
  .logo1EN {
    width: 150px;
    height: 100px;
    background-image: url("../../images/uekEN.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 20px;
  }
  .logo2 {
    width: 150px;
    height: 100px;
    background-image: url("../../images/goit.jpeg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 20px;
  }
  .pdf {
    color: rgba(241, 235, 235, 0.718);
    text-decoration: none;
  }
  .pdf:hover {
    color: white;
  }
}
@media screen and (min-width: 768px) {
  .item {
    width: 100%;
    border-radius: 5px;
    display: flex;
    /* align-items: center;
      justify-content: center; */
    font-family: "Syne Mono", monospace;
    height: 275px;
    color: rgba(241, 235, 235, 0.718);
    font-size: 20px;
    flex-direction: column;
    overflow: hidden;
  }
  .itemTitle {
    font-size: 40px;
    margin-bottom: 40px;
  }
  .education {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .educationLeft {
    display: flex;
    flex-direction: column;
    width: 55%;
    justify-content: center;
    align-items: center;
  }
  .educationRight {
    display: flex;
    flex-direction: column;
    width: 45%;
    justify-content: center;
    align-items: center;
  }
  .logo1 {
    width: 300px;
    height: 100px;
    background-image: url("../../images/uek.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 20px;
  }
  .logo1EN {
    width: 300px;
    height: 100px;
    background-image: url("../../images/uekEN.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 20px;
  }
  .logo2 {
    width: 300px;
    height: 100px;
    background-image: url("../../images/goit.jpeg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 20px;
  }
  .pdf {
    color: rgba(241, 235, 235, 0.718);
    text-decoration: none;
  }
  .pdf:hover {
    color: white;
  }
}
