@media screen and (min-width: 0px) {
  .container {
    width: 100%;
    /* height: 100px; */
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .item {
    border: 1px solid #4fb3aca9;
    width: 80%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Syne Mono", monospace;
    height: 70px;
  }
  .item:hover {
    cursor: pointer;
    background-color: rgba(240, 248, 255, 0.138);
    color: white;
  }
}
@media screen and (min-width: 768px) {
  .container {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    justify-content: center;
    gap: 0;
  }
  .item {
    border: 1px solid #4fb3aca9;
    width: 33.3%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Syne Mono", monospace;
  }
  .item:hover {
    cursor: pointer;
    background-color: rgba(240, 248, 255, 0.138);
    color: white;
  }
}
@media screen and (min-width: 825px) {
  .container {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    margin-top: 120px;
    justify-content: center;
    gap: 0;
  }
  .item {
    border: 1px solid #4fb3aca9;
    width: 33.3%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Syne Mono", monospace;
  }
  .item:hover {
    cursor: pointer;
    background-color: rgba(240, 248, 255, 0.138);
    color: white;
  }
}
@media screen and (min-width: 1080px) {
  .container {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    margin-top: 120px;
    justify-content: center;
    gap: 0;
  }
  .item {
    border: 1px solid #4fb3aca9;
    width: 33.3%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Syne Mono", monospace;
    height: 100px;
  }
  .item:hover {
    cursor: pointer;
    background-color: rgba(240, 248, 255, 0.138);
    color: white;
  }
}
