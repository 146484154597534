html,
body {
  height: 100%;
}
* {
  box-sizing: border-box;
}
.App {
  text-align: center;
  background-image: url("../src/images/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100%; */
  width: 100%;
}

.App-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: rgba(241, 235, 235, 0.718);
  overflow: hidden;
}

.header {
  font-family: "Syne Mono", monospace;
  font-size: 100px;
  margin: 100px 0 20px;
}
.note {
  font-family: "Syne Mono", monospace;
}