@media screen and (min-width: 0px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    gap: 40px;
  }
  .title {
    font-family: "Syne Mono", monospace;
    font-size: 40px;
    color: rgba(241, 235, 235, 0.718);
  }
  .socials {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
    align-items: center;
  }
  .direct {
    display: flex;
    flex-direction: column;
    color: rgba(241, 235, 235, 0.718);
    font-family: "Syne Mono", monospace;
    font-size: 20px;
    gap: 30px;
  }
  .socialIcon:hover {
    cursor: pointer;
  }
  .socialIcon svg {
    transition: transform 0.2s;
    width: 48px;
    height: 48px;
  }
  .socialIcon:hover svg {
    transform: scale(1.2);
  }
  .info:hover {
    cursor: pointer;
  }
}
@media screen and (min-width: 768px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
  }
  .title {
    font-family: "Syne Mono", monospace;
    font-size: 40px;
    color: rgba(241, 235, 235, 0.718);
  }
  .socials {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
    align-items: center;
  }
  .direct {
    display: flex;
    flex-direction: column;
    color: rgba(241, 235, 235, 0.718);
    font-family: "Syne Mono", monospace;
    font-size: 20px;
    gap: 30px;
  }
  .socialIcon:hover {
    cursor: pointer;
  }
  .socialIcon svg {
    transition: transform 0.2s;
    width: 48px;
    height: 48px;
  }
  .socialIcon:hover svg {
    transform: scale(1.2);
  }
  .info:hover {
    cursor: pointer;
  }
}
