.mySwiper {
  color: white;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}



