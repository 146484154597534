@media screen and (min-width: 0px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    gap: 40px;
  }

  .title {
    font-family: "Syne Mono", monospace;
    font-size: 40px;
    color: rgba(241, 235, 235, 0.718);
  }
  .textBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
  }
}
@media screen and (min-width: 577px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
    gap: 40px;
  }

  .title {
    font-family: "Syne Mono", monospace;
    font-size: 40px;
    color: rgba(241, 235, 235, 0.718);
  }
  .textBox {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    height: 400px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
    gap: 40px;
  }

  .title {
    font-family: "Syne Mono", monospace;
    font-size: 40px;
    color: rgba(241, 235, 235, 0.718);
  }
  .textBox {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    height: 300px;
  }
}
