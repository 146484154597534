canvas {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.06;
  z-index: 9999;
  pointer-events: none;
  user-select: none;
  height: 110%;
  width: 100%;
}
