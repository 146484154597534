@media screen and (min-width: 0px) {
  .item {
    width: 100%;
    border-radius: 5px;
    display: flex;
    /* align-items: center;
    justify-content: center; */
    font-family: "Syne Mono", monospace;
    height: 250px;
    color: rgba(241, 235, 235, 0.718);
    font-size: 20px;
    flex-direction: column;
    overflow: scroll;
  }
  .itemTitle {
    font-size: 40px;
    margin-bottom: 40px;
  }

  .skills {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 50px;
  }
  .skill1 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/gglads.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill2 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/js.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill3 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/react.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill4 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/redux.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill5 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/pinterest.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill6 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/node.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill7 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/npm.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill8 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/api.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill9 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/mongo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
@media screen and (min-width: 768px) {
  .item {
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-family: "Syne Mono", monospace;
    height: 260px;
    color: rgba(241, 235, 235, 0.718);
    font-size: 20px;
    flex-direction: column;
    overflow: hidden;
  }
  .itemTitle {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .skills {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 50px;
  }
  .skill1 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/gglads.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill2 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/js.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill3 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/react.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill4 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/redux.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill5 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/pinterest.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill6 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/node.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill7 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/npm.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill8 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/api.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill9 {
    width: 70px;
    height: 70px;
    background-image: url("../../images/mongo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
@media screen and (min-width: 1080px) {
  .item {
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-family: "Syne Mono", monospace;
    height: 260px;
    color: rgba(241, 235, 235, 0.718);
    font-size: 20px;
    flex-direction: column;
    overflow: hidden;
  }
  .itemTitle {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .skills {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 50px;
  }
  .skill1 {
    width: 100px;
    height: 100px;
    background-image: url("../../images/gglads.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill2 {
    width: 100px;
    height: 100px;
    background-image: url("../../images/js.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill3 {
    width: 100px;
    height: 100px;
    background-image: url("../../images/react.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill4 {
    width: 100px;
    height: 100px;
    background-image: url("../../images/redux.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill5 {
    width: 100px;
    height: 100px;
    background-image: url("../../images/pinterest.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill6 {
    width: 100px;
    height: 100px;
    background-image: url("../../images/node.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill7 {
    width: 100px;
    height: 100px;
    background-image: url("../../images/npm.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill8 {
    width: 100px;
    height: 100px;
    background-image: url("../../images/api.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .skill9 {
    width: 100px;
    height: 100px;
    background-image: url("../../images/mongo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .additional {
    margin-bottom: 20px;
  }
}
