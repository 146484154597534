@media screen and (min-width: 0px) {
  .container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;

    gap: 10px;
    justify-content: center;
    margin-bottom: 100px;
    align-items: center;
    font-family: "Syne Mono", monospace;
    font-size: 30px;
  }
  .btn {
    color: #fafafab2;
    text-decoration: none;
  }
  .btn:hover {
    cursor: pointer;
    scale: 1.1;
    color: white;
  }
}
@media screen and (min-width: 768px) {
  .container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;

    gap: 10px;
    justify-content: center;
    margin-bottom: 40px;
    align-items: center;
    font-family: "Syne Mono", monospace;
    font-size: 30px;
  }
  .btn {
    color: #fafafab2;
    text-decoration: none;
  }
  .btn:hover {
    cursor: pointer;
    scale: 1.1;
    color: white;
  }
}
