@media screen and (min-width: 0px) {
  .item {
    width: 100%;
    border-radius: 5px;
    display: flex;
    /* justify-content: center; */
    font-family: "Syne Mono", monospace;
    height: 300px;
    color: rgba(241, 235, 235, 0.718);
    font-size: 20px;
    flex-direction: column;
    overflow: scroll;
  }
  .itemTitle {
    font-size: 40px;
    margin-bottom: 40px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }

  .descriptionBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* padding: 0 60px; */
  }
  .descriptionBoxItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .titleBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .titleBox2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .titleBoxBottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logo {
    height: 44px;
  }
  .titleBoxHead {
    font-size: 36px;
  }
  .titleBoxHead2 {
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    color: #fafafa;
    font-weight: 500;
  }
  .titleBoxHead2green {
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    color: #8baa36;
    font-weight: 500;
  }
  .link {
    text-decoration: none;
  }
  .appDescription {
    padding: 20px;
    font-size: 18px;
  }
  .appInfo {
    margin-top: 30px;
    color: white;
  }
  .gitLink {
    text-decoration: none;
    color: #24cca7;
    margin-top: 30px;
    margin-left: 10px;
  }
  .gitLink:hover {
    color: white;
  }
  .picturesBox {
    width: 320px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  .picture1 {
    width: 300px;
    height: 300px;
    background-image: url("../../images/wallet1.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture2 {
    width: 300px;
    height: 300px;
    background-image: url("../../images/wallet2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture3 {
    width: 300px;
    height: 300px;
    background-image: url("../../images/wallet3.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture4 {
    width: 300px;
    height: 300px;
    background-image: url("../../images/wallet4.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .mySwiper {
    color: white;
    height: 300px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 577px) {
  .item {
    width: 100%;
    border-radius: 5px;
    display: flex;
    /* justify-content: center; */
    font-family: "Syne Mono", monospace;
    height: 300px;
    color: rgba(241, 235, 235, 0.718);
    font-size: 20px;
    flex-direction: column;
  }
  .gitLink {
    text-decoration: none;
    color: #24cca7;
    margin-top: 10px;
    /* margin-left: 10px; */
  }
  .picturesBox {
    width: 450px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  .picture1 {
    width: 420px;
    height: 300px;
    background-image: url("../../images/wallet1.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture2 {
    width: 420px;
    height: 300px;
    background-image: url("../../images/wallet2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture3 {
    width: 420px;
    height: 300px;
    background-image: url("../../images/wallet3.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture4 {
    width: 420px;
    height: 300px;
    background-image: url("../../images/wallet4.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .mySwiper {
    color: white;
    height: 300px;
    width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .item {
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    font-family: "Syne Mono", monospace;
    height: 315px;
    color: rgba(241, 235, 235, 0.718);
    font-size: 20px;
    flex-direction: column;
    overflow: hidden;
  }
  .itemTitle {
    font-size: 40px;
    margin-bottom: 40px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }

  .descriptionBox {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    padding: 0 20px;
  }
  .descriptionBoxItem {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
  }
  .titleBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .titleBox2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logo {
    height: 44px;
  }
  .titleBoxHead {
    font-size: 36px;
  }
  .titleBoxHead2 {
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    color: #fafafa;
    font-weight: 500;
  }
  .titleBoxHead2green {
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    color: #8baa36;
    font-weight: 500;
  }
  .link {
    text-decoration: none;
  }
  .appDescription {
    padding: 0px;
    font-size: 16px;
  }
  .appInfo {
    margin-top: 10px;
    color: white;
  }

  .gitLink {
    text-decoration: none;
    color: #24cca7;
    margin-top: 5px;
    margin-left: 0px;
  }
  .gitLink:hover {
    color: white;
  }
  .picturesBox {
    width: 320px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  .picture1 {
    width: 320px;
    height: 300px;
    background-image: url("../../images/wallet1.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture2 {
    width: 320px;
    height: 300px;
    background-image: url("../../images/wallet2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture3 {
    width: 320px;
    height: 300px;
    background-image: url("../../images/wallet3.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture4 {
    width: 320px;
    height: 300px;
    background-image: url("../../images/wallet4.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .mySwiper {
    color: white;
    height: 300px;
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 1080px) {
  .item {
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    font-family: "Syne Mono", monospace;
    height: 300px;
    color: rgba(241, 235, 235, 0.718);
    font-size: 20px;
    flex-direction: column;
  }
  .itemTitle {
    font-size: 40px;
    margin-bottom: 40px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }

  .descriptionBox {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 60px;
  }
  .descriptionBoxItem {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
  }
  .titleBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .titleBoxBottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logo {
    height: 44px;
  }
  .titleBoxHead {
    font-size: 36px;
  }
  .titleBoxHead2 {
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    color: #fafafa;
    font-weight: 500;
  }
  .titleBoxHead2green {
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    color: #8baa36;
    font-weight: 500;
  }
  .link {
    text-decoration: none;
  }
  .appDescription {
    padding-top: 20px;
    font-size: 16px;
  }
  .appInfo {
    margin-top: 30px;
    color: white;
  }
  .gitLink {
    text-decoration: none;
    color: #24cca7;
    margin-top: 30px;
    margin-left: 10px;
  }
  .gitLink:hover {
    color: white;
  }
  .picturesBox {
    width: 450px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  .picture1 {
    width: 420px;
    height: 300px;
    background-image: url("../../images/wallet1.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture2 {
    width: 420px;
    height: 300px;
    background-image: url("../../images/wallet2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture3 {
    width: 420px;
    height: 300px;
    background-image: url("../../images/wallet3.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture4 {
    width: 420px;
    height: 300px;
    background-image: url("../../images/wallet4.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .mySwiper {
    color: white;
    height: 300px;
    width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1154px) {
  .item {
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    font-family: "Syne Mono", monospace;
    height: 300px;
    color: rgba(241, 235, 235, 0.718);
    font-size: 20px;
    flex-direction: column;
  }
  .itemTitle {
    font-size: 40px;
    margin-bottom: 40px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }

  .descriptionBox {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 60px;
  }
  .descriptionBoxItem {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
  }
  .titleBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .titleBoxBottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logo {
    height: 44px;
  }
  .titleBoxHead {
    font-size: 36px;
  }
  .titleBoxHead2 {
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    color: #fafafa;
    font-weight: 500;
  }
  .titleBoxHead2green {
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    color: #8baa36;
    font-weight: 500;
  }
  .link {
    text-decoration: none;
  }
  .appDescription {
    padding-top: 20px;
  }
  .appInfo {
    margin-top: 30px;
    color: white;
  }
  .gitLink {
    text-decoration: none;
    color: #24cca7;
    margin-top: 30px;
    margin-left: 10px;
  }
  .gitLink:hover {
    color: white;
  }
  .picturesBox {
    width: 450px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  .picture1 {
    width: 420px;
    height: 300px;
    background-image: url("../../images/wallet1.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture2 {
    width: 420px;
    height: 300px;
    background-image: url("../../images/wallet2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture3 {
    width: 420px;
    height: 300px;
    background-image: url("../../images/wallet3.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .picture4 {
    width: 420px;
    height: 300px;
    background-image: url("../../images/wallet4.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .mySwiper {
    color: white;
    height: 300px;
    width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
