@media screen and (min-width: 0px) {
  .job1 {
    width: 150px;
    height: 100px;
    background-image: url("../../images/ams.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .job2 {
    width: 150px;
    height: 100px;
    background-image: url("../../images/surowa.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .item {
    width: 100%;
    border-radius: 5px;
    display: flex;
    /* align-items: center;
  justify-content: center; */
    font-family: "Syne Mono", monospace;
    height: 250px;
    color: rgba(241, 235, 235, 0.718);
    font-size: 20px;
    flex-direction: column;
    overflow: scroll;
  }
  .itemTitle {
    font-size: 40px;
    margin-bottom: 40px;
  }
  .experience {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
 
  }
  .experienceBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .job1 {
    width: 300px;
    height: 100px;
    background-image: url("../../images/ams.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .job2 {
    width: 300px;
    height: 100px;
    background-image: url("../../images/surowa.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .item {
    width: 100%;
    border-radius: 5px;
    display: flex;
    /* align-items: center;
  justify-content: center; */
    font-family: "Syne Mono", monospace;
    height: 275px;
    color: rgba(241, 235, 235, 0.718);
    font-size: 20px;
    flex-direction: column;
    overflow: hidden;
  }
  .itemTitle {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .experience {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .experienceBox {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
  }
}
