
  @media screen and (min-width: 0px) {
    .mySwiper {
      color: white;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  @media screen and (min-width: 577px) {
    .mySwiper {
      color: white;
      height: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
  @media screen and (min-width: 768px) {
    .mySwiper {
      color: white;
      height: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  