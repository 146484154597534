@media screen and (min-width: 0px) {
  .app {
    position: relative;
    text-align: center;
    background-image: url("../../images/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
  }

  .appHeader {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    font-size: calc(10px + 2vmin);
    color: rgba(241, 235, 235, 0.718);
    overflow: hidden;
  }

  .header {
    font-family: "Syne Mono", monospace;
    font-size: 70px;
    margin: 50px 0 20px;
  }
  .note {
    font-family: "Syne Mono", monospace;
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 0px;
  }
  .closed {
    position: absolute;
    bottom: 0;
    height: 0;
    width: 100%;

    overflow: hidden;
    /* visibility: hidden; */
    border-radius: 8px;

    transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
    transform: translateY(100%);
    background-color: transparent;
    display: flex;
    /* justify-content:  flex-end; */
    flex-direction: column;
  }

  .open {
    position: absolute;
    bottom: 0;
    height: 450px;
    width: 100%;
    visibility: visible;
    border-radius: 8px;
    transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
    transform: translateY(0);
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .btnClose {
    width: 100%;
    height: 70px;
    background-color: rgba(255, 255, 255, 0.214);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btnClose:hover {
    cursor: pointer;
  }
  .btnClose:hover svg {
    transform: scale(1.5);
  }
  .btnClose svg {
    width: 50px;
    height: 50px;
    fill: rgba(46, 44, 44, 0.866);
    transition: transform 0.2s;
  }
  .hidden {
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
  .showed {
    width: 100%;
    height: 100%;
    transform: translateY(0);
    transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    flex-direction: column;
  }
}
@media screen and (min-width: 577px) {
  .open {
    position: absolute;
    bottom: 0;
    height: 480px;
    width: 100%;
    visibility: visible;
    border-radius: 8px;
    /* -webkit-box-shadow: inset 0px 14px 25px -3px rgba(66, 81, 103, 0.75);
  -moz-box-shadow: inset 0px 14px 25px -3px rgba(66, 81, 103, 0.75);
  box-shadow: inset 0px 14px 25px -3px rgba(66, 81, 103, 0.75); */
    transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
    transform: translateY(0);
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .app {
    position: relative;
    text-align: center;
    background-image: url("../../images/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* height: 100%; */
    width: 100%;
  }

  .appHeader {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: rgba(241, 235, 235, 0.718);
    overflow: hidden;
  }

  .header {
    font-family: "Syne Mono", monospace;
    font-size: 100px;
    margin: 40px 0 0px;
  }
  .note {
    font-family: "Syne Mono", monospace;
    font-size: 28px;
  }
  .closed {
    position: absolute;
    bottom: 0;
    height: 0;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
    transform: translateY(100%);
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .open {
    position: absolute;
    bottom: 0;
    height: 393px;
    width: 100%;
    visibility: visible;
    border-radius: 8px;
    transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
    transform: translateY(0);
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .btnClose {
    width: 100%;
    height: 70px;
    background-color: rgba(255, 255, 255, 0.214);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btnClose:hover {
    cursor: pointer;
  }
  .btnClose:hover svg {
    transform: scale(1.5);
  }
  .btnClose svg {
    width: 50px;
    height: 50px;
    fill: rgba(46, 44, 44, 0.866);
    transition: transform 0.2s;
  }
  .hidden {
    width: 100%;
    transform: translateY(100%);
    transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
  }
  .showed {
    width: 100%;
    transform: translateY(0);
    transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
  }
}
@media screen and (min-width: 1080px) {
  .app {
    position: relative;
    text-align: center;
    background-image: url("../../images/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  .header {
    font-family: "Syne Mono", monospace;
    font-size: 100px;
    margin: 80px 0 0px;
  }
  .note {
    font-family: "Syne Mono", monospace;
    font-size: 28px;
  }
  .closed {
    position: absolute;
    bottom: 0;
    height: 0;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
    transform: translateY(100%);
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .open {
    position: absolute;
    bottom: 0;
    height: 430px;
    width: 100%;
    visibility: visible;
    border-radius: 8px;
    transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
    transform: translateY(0);
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .btnClose {
    width: 100%;
    height: 70px;
    background-color: rgba(255, 255, 255, 0.214);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btnClose:hover {
    cursor: pointer;
  }
  .btnClose:hover svg {
    transform: scale(1.5);
  }
  .btnClose svg {
    width: 50px;
    height: 50px;
    fill: rgba(46, 44, 44, 0.866);
    transition: transform 0.2s;
  }
  .hidden {
    width: 100%;
    transform: translateY(100%);
    transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
    justify-content: space-between;
  }
  .showed {
    width: 100%;
    transform: translateY(0);
    transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
    justify-content: space-between;
  }
}
